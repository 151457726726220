.pageRandomPick {
    #randomName {
        font-size: 14vh;
        animation: randomNameFadeIn 0.2s ease-in;
        @keyframes randomNameFadeIn {
            0% {
                opacity: 0;
                transform: translate(0, 50%);
            }
            100% {
                opacity: 1;
            }
        }
        width: 5em;
    }

    button {
        border-radius: 50%;
        width: 15vh;
        height: 15vh;
        border: none;
        background: #0092E0; /* Fuck IE */
        background: var(--color-primany);
        font-size: 4vh;
        position: relative;
        cursor: pointer;
        box-shadow: 0.1vh 0.1vh 0.7vh grey;

        > svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 9vh;
            height: 9vh;
            color: white;
        }
    }
}