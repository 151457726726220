@import "Resources/Theme";

$sideNavBarSize: 8vh;

.layout {

    display: flex;
    flex-direction: row;
    height: 100%;

    .main {
        flex: 1;
        padding: 1cm;
        text-align: center;
        font-size: 4vh;

        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            animation: pageIn 0.5s ease-out;
            @keyframes pageIn {
                0% {
                    transform: translateX(-70%) skew(-2deg, -2deg);
                    filter: blur(2px);
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }
    }

    .sideNavBar {
        width: $sideNavBarSize;
        flex-basis: $sideNavBarSize;
        max-width: $sideNavBarSize;
        min-width: $sideNavBarSize;
        position: sticky;
        z-index: 999 !important;
        margin: 0;
        padding: 5vh 0;
        list-style: none;
        display: flex;
        flex-direction: column-reverse;
        text-align: center;

        a {
            height: $sideNavBarSize;
            background: #eee;
            color: #35313d;
            text-align: center;
            position: relative;
            box-shadow: none;
            transition: background 0.1s ease-out, box-shadow 0.1s ease-out;

            > svg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: calc(#{$sideNavBarSize} / 3 * 2);
                height: calc(#{$sideNavBarSize} / 3 * 2);
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
                background: var(--color-primany);
                border-radius: calc(#{$sideNavBarSize} / 10);
                height: 0;
                box-shadow: none;
                transition: height 0.1s ease-out, width 0.1s ease-out, color 0.1s ease-out;
            }

            &:after {
                content: attr(data-tooltip);
                position: absolute;
                left: 100%;
                padding: calc(#{$sideNavBarSize} / 30 * 4);
                top: 50%;
                width: calc(3vh * 5);
                text-align: left;
                color: #35313d;
                font-size: 3vh;
                transform: translate(-10%, -50%) skew(-5deg, -5deg);
                opacity: 0;
                transition: transform 0.2s ease-out, opacity 0.1s ease-out;
            }

            &:hover:after {
                transform: translate(0, -50%);
                filter: none;
                opacity: 1;
            }

            &.active {
                background: white;
                border-radius: 0 calc(#{$sideNavBarSize} / 6) calc(#{$sideNavBarSize} / 6) 0;
                box-shadow: 0 calc(#{$sideNavBarSize} / 7.5) calc(#{$sideNavBarSize} / 3.75) 0 rgba(black, 0.1);
                color: var(--color-primany);

                &:before {
                    height: 80%;
                    width: calc(#{$sideNavBarSize} / 30 * 2);
                }
            }
        }

        .bottom {
            margin-top: auto;
        }

        .split {
            width: 70%;
            margin: calc(#{$sideNavBarSize} / 7.5) auto;
            border-bottom: calc(#{$sideNavBarSize} / 60) solid #D2D0CE;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }
}