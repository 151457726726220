/* TaiwanPearl */
@font-face {
    font-family: TaiwanPearl;
    font-weight: 200;
    font-display: swap;
    src: url("Fonts/TaiwanPearl-ExtraLight.woff2") format("woff2"),
    url("Fonts/TaiwanPearl-ExtraLight.woff") format("woff");
}

@font-face {
    font-family: TaiwanPearl;
    font-weight: 300;
    font-display: swap;
    src: url("Fonts/TaiwanPearl-Light.woff2") format("woff2"),
    url("Fonts/TaiwanPearl-Light.woff") format("woff");
}

@font-face {
    font-family: TaiwanPearl;
    font-weight: normal;
    font-display: swap;
    src: url("Fonts/TaiwanPearl-Regular.woff2") format("woff2"),
    url("Fonts/TaiwanPearl-Regular.woff") format("woff");
}

@font-face {
    font-family: TaiwanPearl;
    font-weight: 500;
    font-display: swap;
    src: url("Fonts/TaiwanPearl-Medium.woff2") format("woff2"),
    url("Fonts/TaiwanPearl-Medium.woff") format("woff");
}

@font-face {
    font-family: TaiwanPearl;
    font-weight: 600;
    font-display: swap;
    src: url("Fonts/TaiwanPearl-SemiBold.woff2") format("woff2"),
    url("Fonts/TaiwanPearl-SemiBold.woff") format("woff");
}